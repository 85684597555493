<template>
  <div id="Setting-Info">
    <a-form-model
      ref="settingInfo"
      :model="settingInfo.item"
      :rules="settingInfo.rules"
      v-bind="{
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      }"
    >
      <a-form-model-item has-feedback label="真实姓名" prop="a">
        <a-input
          autocomplete="off"
          v-model="settingInfo.item.real_name"
          :disabled="!!user.real_name"
          :placeholder="`请输入真实姓名`"
        >
          <!-- <a-icon slot="prefix" type="lock" /> -->
        </a-input>
      </a-form-model-item>
      <a-form-model-item has-feedback label="身份证证件号码" prop="b">
        <a-input
          autocomplete="off"
          v-model="settingInfo.item.card"
          :disabled="!!user.card"
          :placeholder="`身份证证件号码`"
        >
          <!-- <a-icon slot="prefix" type="lock" /> -->
        </a-input>
      </a-form-model-item>
      <a-form-model-item has-feedback label="出生日期" prop="c">
        <a-input
          autocomplete="off"
          v-show="false"
          v-model="settingInfo.item.birthday"
        >
          <!-- <a-icon slot="prefix" type="lock" /> -->
        </a-input>
        <a-date-picker
          placeholder="请选择生日"
          :style="{ width: '100%' }"
          @change="
            (date, dateString) => (settingInfo.item.birthday = dateString)
          "
        />
      </a-form-model-item>
      <a-form-model-item has-feedback label="性别" prop="d">
        <a-select v-model="settingInfo.item.sex" placeholder="请输入性别">
          <a-select-option value="男">男</a-select-option>
          <a-select-option value="女">女</a-select-option>
          <a-select-option value="保密">保密</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item has-feedback label="地址" prop="e">
        <a-input v-model="settingInfo.item.address" placeholder="请输入住址" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          id="login"
          @click="submitSettingInfo('settingInfo')"
          :disabled="submitButton"
        >
          确认修改实名信息
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import "@/components/js/_form_common";
import moment from "moment";

export default {
  name: "Setting-Info",
  props: {},
  data() {
    return {
      window,
      user: JSON.parse(localStorage.getItem("user")),
      settingInfo: {
        item: {
          real_name: "", // 姓名
          cerd: "", // 身份证
          birthday: "", // 生日 moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,"YYYY-MM-DD")
          sex: "保密", // 性别 0保密 1男 2女
          address: "", // 地址
        },
        rules: {
          OldPassword: [
            {
              required: true,
              whitespace: true,
              trigger: ["change", "blur"],
              validator: (rule, value, callback) => {
                console.log(rule, value, callback);
              },
            },
          ],
        },
      },
      submitButton: false,
    };
  },
  methods: {
    moment,
    submitSettingInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const datas = this.settingInfo.item;
          console.log(datas);
          this.submitButton = true;
          this.$axios
            .post(
              "personal-center/modify-information",
              this.$axios.qs.stringify({
                ...datas,
              })
            )
            .then((res) => {
              this.submitButton = false;
              if (res.code === 0) {
                this.$message.success("修改成功");
                setTimeout(() => {
                  this.$router.back(-1);
                }, 500);
              }
            });
        }
      });
    },
  },
  created() {
    Object.assign(this.settingInfo.item, this.user);
    // this.settingInfo.item.a = this.user.real_name;
    // this.settingInfo.item.b = this.user.cerd;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Setting-Info {
  padding: 2em 0;

  .ant-form {
    //: 0.3rem;
    color: white !important;

    * {
      //: inherit;
    }

    > .ant-form-item:last-child {
      .ant-btn {
        width: 100%;
        color: white;
        border-color: #828ac3;
        background-color: #3d447b;
        height: 3em;
        line-height: 3em;
        padding: 0 0.5em;
      }
    }
  }
}
</style>
